import React from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import athletesCable from "../../channels/athletes";

import DepthChart from "./DepthChart";
import Filter from "./Filter";

export default class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      team: props.team,
      schema: props.schema,
      practice: props.practice,
    };

    props.storeAthletes(props.initialAthletes);
    props.storeTeams(props.initialTeams);
    props.storeDepthCharts(props.initialDepthCharts);
    props.storeStaff(props.staff);

    athletesCable({
      received: ({ data, type }) => {
        switch (type) {
          case "UPDATE_ATHLETE":
            props.updateAthlete(data);
            break;
          case "REMOVE_ATHLETE":
            props.removeAthlete(data);
            break;
          default:
        }
      },
    });

    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  handleFilterChange(value, name) {
    this.setState((prevState, props) => {
      let newState = {
        ...prevState,
        [name]: value,
      };

      props.getDepthCharts({ team: newState.team, schema: newState.schema, practice: newState.practice });

      $(".print_link").each(function () {
        const params = new URLSearchParams(this.search);
        params.delete("team[]");
        newState.team.forEach((team_id) => params.append("team[]", team_id));
        params.delete("schema[]");
        newState.schema.forEach((schema_id) => params.append("schema[]", schema_id));
        params.set("practice", newState.practice);

        this.search = params.toString();
      });

      return newState;
    });
  }

  render() {
    let { filteredDepthCharts, teams } = this.props;
    const depthChartComponents = filteredDepthCharts.map((chartId, index) => (
      <DepthChart chartId={chartId} key={chartId} />
    ));
    return (
      <DndProvider backend={HTML5Backend}>
        <div className="row">
          <div className="col-md-12">
            <Filter handleChange={this.handleFilterChange} values={this.state} teams={teams} />
            <hr className="hidden-print" />
            {depthChartComponents}
          </div>
        </div>
      </DndProvider>
    );
  }
}
