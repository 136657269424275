import React from 'react'
import titleize from '../../lib/titleize'

export const Title = ({ depthChart }) => {
  return (
    <h4>
      <div className='row'>
        <div className='col-xs-3 text-left'>
          { !depthChart.practice && depthChart.team.name }
        </div>
        <div className='col-xs-6 text-center'>
          <ul className='list-inline'>
            <li>
              { depthChart.name }
            </li>
            <li>
              <small><a href={`/depth_charts/${depthChart.id}/edit`}><span className="glyphicon glyphicon-pencil hidden-print" /></a></small>
            </li>
          </ul>
        </div>
        <div className='col-xs-3 text-right'>
          <label className={`label label-${ depthChart.practice ? 'default' : 'primary' }`}>{ depthChart.practice ? 'Practice' : 'Game Day' } - { titleize(depthChart.chart_schema) }</label>
        </div>
      </div>
    </h4>
  )
}

export default Title
