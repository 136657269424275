import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./reducer";

import PageContainer from "./PageContainer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export const DepthChartsContainer = props => (
  <Provider store={store}>
    <PageContainer {...props} />
  </Provider>
);

export default DepthChartsContainer;
