import { connect } from 'react-redux'
import filter from 'lodash/filter'
import find from 'lodash/find'
import indexOf from 'lodash/indexOf'

import { updateStaff } from './reducer'

import PositionDropzone from './PositionDropzone'

const mapDispatchToProps = {
  updateStaff
}

const mapStateToProps = (state, props) => {
  let athlete = props.position_athlete ? find(state.athletes, { id: props.position_athlete.athlete_id }) : null
  let athletes = filter(state.athletes, (athlete) => {
    let gradeMatch = props.gradesAllowed ? (indexOf(props.gradesAllowed, athlete.grade.toString()) > -1) : true
    if (props.position.position) {
      return (find(athlete.positions, {id: props.position.position.id}) && gradeMatch)
    } else {
      return gradeMatch
    }
  })

  let acceptedAthletes = athletes.map(athlete => athlete.id.toString())

  return {
    athletes,
    athlete,
    acceptedAthletes,
    staff: state.staff
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PositionDropzone)
