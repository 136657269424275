import { connect } from 'react-redux'

import AthleteList from './AthleteList'

const mapDispatchToProps = {}

const mapStateToProps = (state) => ({
  athletes: state.athletes
})

export default connect(mapStateToProps, mapDispatchToProps)(AthleteList)
