import React from 'react'
import PropTypes from "prop-types"
import axios from 'axios';
import flatten from 'lodash/flatten'
import maxBy from 'lodash/maxBy'

import depthChartCable from '../../channels/depth_chart'

import Title from './Title'
import Column from './Column'
import DepthChartLoading from './DepthChartLoading'

export class DepthChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      depthChart: null,
      useFallback: true
    }
    this.cancelSource = axios.CancelToken.source()

    const { chartId } = this.props

    this.getDepthChart()
    this.depthChartSubscription = depthChartCable(chartId, {
      received: ({ data, type }) => {
        switch (type) {
          case 'UPDATE_DEPTH_CHART':
            this.storeDepthChart(data)
            break;
          default:
        }
      },
      connected: () => {
        this.setState({ useFallback: false })
      },
      disconnected: () => {
        this.setState({ useFallback: true })
      }
    })

    this.storeDepthChart = this.storeDepthChart.bind(this)
  }

  componentWillUnmount() {
    this.depthChartSubscription.unsubscribe()
    this.cancelSource.cancel()
  }

  getDepthChart() {
    const { chartId } = this.props

    this.cancelSource.cancel()
    this.cancelSource = axios.CancelToken.source()

    axios.get(`/depth_charts/${chartId}.json`, {
      cancelToken: this.cancelSource.token
    }).then(({ data }) => this.storeDepthChart(data)).catch(thrown => {})
  }

  storeDepthChart(depthChart, fallback = false) {
    let { useFallback } = this.state
    if (fallback && !useFallback) {
      return
    }

    let deepestAthlete = maxBy(flatten(depthChart.depth_chart_positions.map(position => maxBy(position.depth_chart_position_athletes, 'rank'))), 'rank')
    depthChart.rows = deepestAthlete ? deepestAthlete.rank : 0
    this.setState({depthChart})
  }

  render() {
    let { depthChart } = this.state

    if (depthChart) {
      return (
        <div className='depthchart__container'>
          <Title depthChart={depthChart} />
          <div className={`depthchart ${depthChart.depth_chart_positions.length > 11 ? 'depthchart--small' : ''}`}>
            { depthChart.depth_chart_positions.map((depthChartPosition, index) => (
              <Column
                depthChart={depthChart}
                depthChartPosition={depthChartPosition}
                storeDepthChart={this.storeDepthChart}
                key={depthChartPosition.id}
                rows={depthChart.rows}
                gradesAllowed={depthChart.team ? depthChart.team.grades_allowed : null}
                ordinal_index={index}
              />
            )) }
          </div>
        </div>
      )
    } else {
      return (<div className='depthchart__container'><DepthChartLoading /></div>)
    }
  }
}

export default DepthChart
