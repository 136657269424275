import React from 'react'
import PropTypes from "prop-types"
import { DragSource } from 'react-dnd'

const athleteSource = {
  beginDrag(props) {
    return {
      athlete: props.athlete
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

@DragSource(props => props.athlete ? props.athlete.id.toString() : 'none', athleteSource, collect)
export default class Athlete extends React.Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired
  }

  render() {
    const { connectDragSource, isDragging, athlete } = this.props

    let athleteClasses = ['depthchart__athlete']
    if (athlete.status === 'injured') athleteClasses.push('text-danger')
    if (athlete.status === 'ineligible') athleteClasses.push('ineligible-athlete')
    if (isDragging) athleteClasses.push('depthchart__athlete--dragging')

    return connectDragSource(
      <span className={ athleteClasses.join(' ') }>
        {athlete.last_name}, {athlete.first_name[0]}{athlete.middle_initial}
      </span>
    )
  }
}
