import React from 'react'
import Select2 from './Select2'

const schemas = [
  { id: 'offense', text: 'Offense' },
  { id: 'defense', text: 'Defense' },
  { id: 'kick_off', text: 'Kick Off' },
  { id: 'kick_off_return', text: 'Kick Off Return' },
  { id: 'hands_team', text: 'Hands Team' },
  { id: 'punt', text: 'Punt' },
  { id: 'punt_return', text: 'Punt Return' },
  { id: 'field_goal', text: 'Field Goal' },
]

const chartType = [
  { id: '', text: 'Game Day & Practice'},
  { id: 'false', text: 'Game Day Only'},
  { id: 'true', text: 'Practice Day Only'}
]

export const Filter = ({ handleChange, values, teams }) => (
  <div className='row hidden-print'>
    <div className='col-md-4'>
      <div className="form-group">
        <label>Scheme(s)</label>
        <Select2
          multiple
          handleChange={(value) => handleChange(value, 'schema')}
          selected={values.schema}
          data={schemas}
        />
      </div>
    </div>
    <div className='col-md-4'>
      <div className="form-group">
        <label>Team(s)</label>
        <Select2
          multiple
          handleChange={(value) => handleChange(value, 'team')}
          selected={values.team}
          data={teams.map(team => ({ id: team.id, text: team.name }))}
        />
      </div>
    </div>
    <div className='col-md-4'>
      <div className="form-group">
        <label>Game Day/Practice</label>
        <Select2
          handleChange={(value) => handleChange(value, 'practice')}
          selected={values.practice}
          data={chartType}
        />
      </div>
    </div>
  </div>
)

export default Filter
