import React from 'react'

export const DepthChartLoading = () => (
  <div className='depthchart__loading'>
    <div className='depthchart__loading__loader'>
      <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
      <span className="sr-only">Loading...</span>
    </div>
  </div>
)

export default DepthChartLoading
