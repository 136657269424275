import React from 'react'
import find from 'lodash/find'

import PositionDropzoneContainer from './PositionDropzoneContainer'

export const Column = ({ depthChart, storeDepthChart, depthChartPosition, gradesAllowed, ordinal_index, rows }) => {
  let positions = []
  for (var i = 0; i <= rows + 1; i++) {
    let position_athlete = find(depthChartPosition.depth_chart_position_athletes, { rank: i }) || null
    positions.push(
      <PositionDropzoneContainer
        depth_chart={depthChart}
        storeDepthChart={storeDepthChart}
        gradesAllowed={gradesAllowed}
        position={depthChartPosition}
        position_athlete={position_athlete}
        ordinal_index={ordinal_index}
        rank={i}
        key={i}
        print={i <= rows}
      />
    )
  }
  return (
    <div className='depthchart__column'>
      <div className='depthchart__header'>{depthChartPosition.name}</div>
      {positions}
    </div>
  )
}

export default Column
