import { connect } from 'react-redux'
import find from 'lodash/find'

import AthleteContextMenu from './AthleteContextMenu'

const mapDispatchToProps = {}

const mapStateToProps = (state, props) => ({
  athlete: find(state.athletes, { id: props.athlete.id })
})

export default connect(mapStateToProps, mapDispatchToProps)(AthleteContextMenu)
