import React from 'react'

export class Select2 extends React.Component {
  componentDidMount() {
    this.renderSelect()
  }

  render() {
    let { handleChange, data, selected, multiple } = this.props
    return (
      <select multiple={multiple} className="form-control native" ref="selectContainer" value={selected} onChange={() => {}} style={{ width: '100%' }}>
        {data.map(option => (
          <option key={option.id} value={option.id}>{option.text}</option>
        ))}
      </select>
    )
  }

  renderSelect() {
    let { handleChange, data, selected, searchable } = this.props
    $(this.refs.selectContainer).select2({
      theme: 'bootstrap',
      minimumResultsForSearch: searchable ? Infinity : 1
    }).on('select2:select select2:unselect', function() {
      handleChange($(this).select2('val') || [])
    })
  }
}

export default Select2
