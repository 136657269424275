import axios from "axios";

import findIndex from "lodash/findIndex";

// ------------------------------------
// Constants
// ------------------------------------
export const STORE_ATHLETES = "STORE_ATHLETES";
export const UPDATE_ATHLETE = "UPDATE_ATHLETE";
export const REMOVE_ATHLETE = "REMOVE_ATHLETE";
export const STORE_TEAMS = "STORE_TEAMS";
export const STORE_DEPTH_CHARTS = "STORE_DEPTH_CHARTS";
export const STORE_STAFF = "STORE_STAFF";
export const HIDE_WARNINGS = "HIDE_WARNINGS";

let cancelSource = axios.CancelToken.source();

// ------------------------------------
// Actions
// ------------------------------------
export function storeAthletes(athletes) {
  return {
    type: STORE_ATHLETES,
    payload: athletes,
  };
}

export function updateAthlete(athlete) {
  return {
    type: UPDATE_ATHLETE,
    payload: athlete,
  };
}

export function removeAthlete(athlete_id) {
  return {
    type: REMOVE_ATHLETE,
    payload: athlete_id,
  };
}

export function storeTeams(teams) {
  return {
    type: STORE_TEAMS,
    payload: teams,
  };
}

export function storeDepthCharts(depthCharts) {
  return {
    type: STORE_DEPTH_CHARTS,
    payload: depthCharts,
  };
}

export function storeStaff(staff) {
  return {
    type: STORE_STAFF,
    payload: staff,
  };
}

export const getDepthCharts = (searchParams = { team: [], schema: [], practice: null }) => {
  cancelSource.cancel();
  cancelSource = axios.CancelToken.source();
  return (dispatch, _getState) => {
    return axios
      .get("/depth_charts.json", {
        params: searchParams,
        cancelToken: cancelSource.token,
      })
      .then(({ data }) => dispatch(storeDepthCharts(data.map((depthChart) => depthChart.id))))
      .catch((thrown) => {});
  };
};

export const updateStaff = (params = {}) => {
  return (dispatch, getState) => {
    let staff = getState().staff;
    return axios
      .put(`/${staff.route_name}/${staff.id}.json`, params)
      .then(({ data }) => dispatch(storeStaff(data)))
      .catch((thrown) => {});
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [STORE_ATHLETES]: (state, action) => ({
    ...state,
    athletes: action.payload,
  }),
  [UPDATE_ATHLETE]: (state, action) => {
    let athleteIndex = findIndex(state.athletes, { id: action.payload.id });
    if (athleteIndex > -1) {
      return {
        ...state,
        athletes: [...state.athletes.slice(0, athleteIndex), action.payload, ...state.athletes.slice(athleteIndex + 1)],
      };
    } else {
      return {
        ...state,
        athletes: [action.payload, ...state.athletes],
      };
    }
  },
  [REMOVE_ATHLETE]: (state, action) => {
    let athleteIndex = findIndex(state.athletes, { id: action.payload });
    if (athleteIndex > -1) {
      return {
        ...state,
        athletes: [...state.athletes.slice(0, athleteIndex), ...state.athletes.slice(athleteIndex + 1)],
      };
    } else {
      return state;
    }
  },
  [STORE_TEAMS]: (state, action) => ({
    ...state,
    teams: action.payload,
  }),
  [STORE_DEPTH_CHARTS]: (state, action) => ({
    ...state,
    filteredDepthCharts: action.payload,
  }),
  [STORE_STAFF]: (state, action) => ({
    ...state,
    staff: {
      ...state.staff,
      ...action.payload,
    },
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  athletes: [],
  teams: [],
  filteredDepthCharts: [],
  staff: {},
};
export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
