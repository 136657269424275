import React from "react";

export default class AthleteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
    };

    this.filterAthletes = this.filterAthletes.bind(this);
  }

  filterAthletes(event) {
    this.setState({
      filter: event.target.value,
    });
  }

  getGradeName(grade) {
    switch (grade) {
      case 12:
        return "Sr";
      case 11:
        return "Jr";
      case 10:
        return "So";
      case 9:
        return "Fr";
      default:
        return grade;
    }
  }

  buildPositions(athlete_positions, position) {
    let filteredPositions = athlete_positions;
    if (position) {
      return filteredPositions
        .sort((athlete_position) => athlete_position.id !== position.id)
        .map((athlete_position) => (
          <div
            className={`label label-${position.id == athlete_position.id ? "primary" : "default"}`}
            key={athlete_position.id}
          >
            {athlete_position.abbreviation}
          </div>
        ));
    } else {
      return filteredPositions.map((athlete_position) => (
        <div className="label label-default" key={athlete_position.id}>
          {athlete_position.abbreviation}
        </div>
      ));
    }
  }

  render() {
    let { handleClick, acceptedAthletes, position, athletes } = this.props;
    let { filter } = this.state;

    let athleteList = athletes
      .filter((athlete) => acceptedAthletes.indexOf(athlete.id.toString()) > -1)
      .filter(
        (athlete) =>
          athlete.last_name.toLowerCase().substr(0, filter.length) === filter.toLowerCase() ||
          athlete.first_name.toLowerCase().substr(0, filter.length) === filter.toLowerCase()
      )
      .sort((a1, a2) => {
        if (a1.status !== a2.status) {
          if (a1.status != "playing") {
            return 1;
          } else if (a2.status != "playing") {
            return -1;
          }
        } else if (a1.graduation_year !== a2.graduation_year) {
          return a1.graduation_year - a2.graduation_year;
        } else if (a1.last_name < a2.last_name) {
          return -1;
        } else if (a1.last_name > a2.last_name) {
          return 1;
        }
        return 0;
      })
      .map((athlete) => (
        <li
          className="list-group-item depthchart__athletelist__athlete"
          onClick={() => handleClick(athlete)}
          key={athlete.id}
        >
          <strong className={athlete.status === "injured" ? "text-danger" : ""}>
            {athlete.last_name}, {athlete.first_name[0]}
            {athlete.middle_initial}
          </strong>{" "}
          ({this.getGradeName(athlete.grade)})
          <div className="pull-right">{this.buildPositions(athlete.positions, position)}</div>
        </li>
      ));

    return (
      <div className="panel panel-default">
        <div className="panel-heading">Athletes</div>
        <input
          type="text"
          className="form-control"
          placeholder="Filter athletes"
          onChange={this.filterAthletes}
          autoFocus
        />
        <ul className="list-group">{athleteList}</ul>
      </div>
    );
  }
}
