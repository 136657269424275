var LocalTime = require("local-time");
LocalTime.start();

var axios = require("axios");
axios.defaults.headers.common["X-CSRF-Token"] = $('meta[name="csrf-token"]').attr("content");

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

require("forms/roster");
