import React from 'react'
import axios from 'axios';
import titleize from '../../lib/titleize'

const toggleCaptain = (athlete, event) => {
  event.preventDefault()
  axios.put(`/athletes/${athlete.id}.json`, { captain: !athlete.captain })
}

const setStatus = (athlete, status, event) => {
  event.preventDefault()
  axios.put(`/athletes/${athlete.id}.json`, { status })
}

const removeAthlete = (athlete, depth_chart, position, rank, event, storeDepthChart) => {
  event.preventDefault()
  axios.delete(`/depth_charts/${depth_chart.id}/depth_chart_positions/${position.id}/depth_chart_position_athletes/${rank}.json`, { athlete_id: athlete.id }).then(({data}) => storeDepthChart(data, true))
}

export const AthleteContextMenu = ({ athlete, depth_chart, position, rank, storeDepthChart }) => (
  <ul className="dropdown-menu" style={{ display: 'block' }}>
    <li>
      <a href={`/athletes/${athlete.id}/edit`} target='_blank'>
        Edit athlete
      </a>
    </li>
    <li role="separator" className="divider"></li>
    <li>
      <a href="#" onClick={(event) => toggleCaptain(athlete, event)}>
        {athlete.captain ? 'Demote captain' : 'Make captain'}
      </a>
    </li>
    { athlete.status != 'playing' &&
      <li><a href="#" onClick={(event) => setStatus(athlete, 'playing', event)}>Set to playing</a></li>
    }
    { athlete.status != 'injured' &&
      <li><a href="#" onClick={(event) => setStatus(athlete, 'injured', event)}>Set to injured</a></li>
    }
    { athlete.status != 'ineligible' &&
      <li><a href="#" onClick={(event) => setStatus(athlete, 'ineligible', event)}>Set to ineligible</a></li>
    }
    <li role="separator" className="divider"></li>
    <li>
      <a href="#" onClick={(event) => removeAthlete(athlete, depth_chart, position, rank, event, storeDepthChart)}>
        <span className='text-danger'>Remove from chart</span>
      </a>
    </li>
  </ul>
)

export default AthleteContextMenu
