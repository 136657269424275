import { connect } from 'react-redux'
import { getDepthCharts, storeAthletes, storeTeams, storeDepthCharts, storeStaff, updateAthlete, removeAthlete } from './reducer'

import Page from './Page'

const mapDispatchToProps = {
  getDepthCharts,
  storeAthletes,
  storeTeams,
  storeDepthCharts,
  storeStaff,
  updateAthlete,
  removeAthlete
}

const mapStateToProps = (state) => ({
  athletes: state.athletes,
  teams: state.teams,
  filteredDepthCharts: state.filteredDepthCharts
})

export default connect(mapStateToProps, mapDispatchToProps)(Page)
