import React from 'react'
import PropTypes from "prop-types"
import { DropTarget } from 'react-dnd'
import axios from 'axios';

import Athlete from './Athlete'
import AthleteListContainer from './AthleteListContainer'
import AthleteContextMenuContainer from './AthleteContextMenuContainer'
import { Button, Modal } from 'react-bootstrap'

const positionTarget = {
  drop(props, monitor, component) {
    let athlete = monitor.getItem()
    component.putAthlete(athlete.athlete)
  }
};

@DropTarget(props => props.acceptedAthletes, positionTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
}))
export class PositionDropzone extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      athleteList: false,
      athleteContextMenu: false,
      x: 0,
      y: 0,
      warningModal: {
        show: false,
        athlete: null
      },
      disableWarningModals: false
    }

    this.handlePositionClick = this.handlePositionClick.bind(this)
    this.handleContextClick = this.handleContextClick.bind(this)
    this.handleAthleteSelection = this.handleAthleteSelection.bind(this)
    this.dismissClickHandler = this.dismissClickHandler.bind(this)
    this.dismissContextClickHandler = this.dismissContextClickHandler.bind(this)
    this.putAthlete = this.putAthlete.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.openModal = this.openModal.bind(this)
    this.toggleDisableWarningModal = this.toggleDisableWarningModal.bind(this)
  }

  closeModal() {
    this.setState({
      warningModal: {
        show: false,
        athlete: null
      }
    })
  }

  openModal(athlete) {
    this.setState({
      warningModal: {
        show: true,
        athlete
      }
    })
  }

  toggleDisableWarningModal(e) {
    this.setState({
      disableWarningModals: e.target.checked
    })
  }

  putAthlete(athlete, confirmed = false) {
    const { depth_chart, position, rank, staff, updateStaff, storeDepthChart } = this.props
    const { disableWarningModals } = this.state

    if (!staff.show_warnings || confirmed || !depth_chart.team || !athlete.team_id || depth_chart.team.id == athlete.team_id) {
      this.closeModal()
      axios.put(`/depth_charts/${depth_chart.id}/depth_chart_positions/${position.id}/depth_chart_position_athletes/${rank}.json`, { athlete_id: athlete.id }).then(({data}) => storeDepthChart(data, true))

      if (staff.show_warnings && disableWarningModals) {
        updateStaff({ show_warnings: false })
      }

    } else {
      this.openModal(athlete)
    }
  }

  handlePositionClick(event) {
    if (!this.state.athleteContextMenu) event.preventDefault()

    if (!this.state.athleteList) {
      document.addEventListener('click', this.dismissClickHandler, false)
      document.addEventListener('contextmenu', this.dismissContextClickHandler, false)

      this.setState({
        athleteList: true,
        athleteContextMenu: false,
        x: event.nativeEvent.layerX,
        y: event.nativeEvent.layerY
      })
    }
  }

  dismissClickHandler(e) {
    const setState = this.setState.bind(this)
    const list = this.list

    if ('2' != e.button && !list.contains(e.target)) {
      document.removeEventListener('click', this.dismissClickHandler, false)
      document.removeEventListener('contextmenu', this.dismissContextClickHandler, false)
      setState({
        athleteList: false,
        athleteContextMenu: false
      })
    }
  }

  dismissContextClickHandler(e) {
    const setState = this.setState.bind(this)
    const list = this.list

    if (!list.contains(e.target)) {
      document.removeEventListener('click', this.dismissClickHandler, false)
      document.removeEventListener('contextmenu', this.dismissContextClickHandler, false)
      setState({
        athleteList: false,
        athleteContextMenu: false
      })
    }
  }

  handleAthleteSelection(athlete) {
    this.putAthlete(athlete)

    this.setState({
      athleteList: false
    })
  }

  handleContextClick(event) {
    if (!this.state.athleteList) event.preventDefault()

    if (!this.state.athleteContextMenu) {
      event.preventDefault()
      document.addEventListener('click', this.dismissClickHandler, false)
      document.addEventListener('contextmenu', this.dismissContextClickHandler, false)

      this.setState({
        athleteList: false,
        athleteContextMenu: true,
        x: event.nativeEvent.layerX,
        y: event.nativeEvent.layerY
      })
    }
  }

  render() {
    const { position, athlete, acceptedAthletes, connectDropTarget, isOver, canDrop, ordinal_index, print } = this.props
    const { warningModal, disableWarningModals, athleteList, athleteContextMenu, x, y } = this.state
    const isActive = isOver && canDrop;

    let positionDropzoneClasses = ['depthchart__positiondropzone']
    if (isActive) {
      positionDropzoneClasses.push('depthchart__positiondropzone--hover')
    } else if (canDrop) {
      positionDropzoneClasses.push('depthchart__positiondropzone--candrop')
    } else if (athlete) {
      switch (athlete.grade) {
        case 9:
          positionDropzoneClasses.push('depthchart__positiondropzone--9')
          break;
        case 10:
          positionDropzoneClasses.push('depthchart__positiondropzone--10')
          break;
        case 11:
          positionDropzoneClasses.push('depthchart__positiondropzone--11')
          break;
        case 12:
          positionDropzoneClasses.push('depthchart__positiondropzone--12')
          break;
      }
    } else if (ordinal_index >= 11) {
      positionDropzoneClasses.push('depthchart__positiondropzone--grey')
    }

    // Hide on print
    // TODO: Fix border bottom for this to work
    // if (!print) positionDropzoneClasses.push('hidden-print')

    let athleteListClasses = ['depthchart__athletelist']
    if (athleteList || athleteContextMenu) athleteListClasses.push('depthchart__athletelist--active')

    return connectDropTarget(
      <div className={ positionDropzoneClasses.join(' ') } onClick={this.handlePositionClick} onContextMenu={this.handleContextClick}>
        {athlete &&
          <Athlete athlete={athlete} />
        }
        <div ref={(div) => this.list = div} className={ athleteListClasses.join(' ') } style={{ top: `${y}px`, left: `${x}px` }}>
          {athleteList &&
            <AthleteListContainer handleClick={this.handleAthleteSelection} acceptedAthletes={acceptedAthletes} position={position.position} />
          }
          {athlete && athleteContextMenu &&
            <AthleteContextMenuContainer athlete={athlete} {...this.props} />
          }
        </div>
        <Modal show={warningModal.show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Change Athlete's Team</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Moving this athlete will change their team. This will add them to the new depth chart and remove them from any depth charts associated with their old team. Are you sure want to proceed?</p>
          </Modal.Body>
          <Modal.Footer>
            <div className='pull-left checkbox'>
              <label>
                <input
                  type="checkbox"
                  checked={disableWarningModals}
                  onChange={this.toggleDisableWarningModal} /> Disable these warnings
              </label>
            </div>
            <Button onClick={this.closeModal}>Cancel</Button>
            <Button bsStyle='warning' onClick={() => this.putAthlete(warningModal.athlete, true)}>Move athlete to new team</Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default PositionDropzone
